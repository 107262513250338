export const en = {
    translation: {
        //---------------- PORTAL ----------------//
        // Home
        week: "Week",
        hi: "Hi",
        task: "Chapter",
        toComplete: "to complete this week",
        achieved: "Achieved",
        hasCompleted: "since the beginning of your program",
        achievements: "Achievements",
        achievements_tooltip: "Complete your weekly chapters and tasks to accumulate points",
        earned: "You've earned",
        inARow: "in a Row",
        chapter: "Chapter",
        skipChapter: "Skip this chapter",
        syn2Dialog: "The Brain Health Program website for the SYNERGIC-2 study has been updated. Please click here to be directed to the new URL.",
        homeDesc: "To view a chapter, just click on its name in the box",
        // DrawerContent
        home: "Dashboard",
        formtest: "Forms test",
        profile: "Profile",
        topics: "Topics",
        favourites: "Favourites",
        completed: "Completed",
        notebook: "Notebook",
        exportInfo: "Please click here to download your notebook notes.",
        help: "Help",
        logout: "Log out",
        // PortalLayout
        textSize: "Text Size",
        // Topic titles
        brainOverviewTitle: "Brain Health Overview",
        physicalTitle: "Physical Activity",
        cognitiveTitle: "Cognitive Engagement",
        nutritionTitle: "Nutrition",
        sleepTitle: "Sleep",
        socialTitle: "Social & Psychological Health",
        vascularTitle: "Vascular Health",
        visionTitle: "Vision & Hearing",
        efficacyTitle: "Self Efficacy",
        dementiaTitle: "Dementia Literacy",
        attitudesTitle: "Attitudes towards dementia",
        // Risks
        risk: "RISK",
        lowRisk: "Low Risk",
        moderateRisk: "Moderate Risk",
        highRisk: "High Risk",
        low: "LOW",
        high: "HIGH",
        // Nav
        login: "Log in",
        // Landing
        brainHealthPro: "Brain Health PRO",
        brainHealthProDescription: "You can make changes to reduce your dementia risk. Brain health PRO will show you how.",
        register: "Register",
        registerText: "Let's get started !",
        loginText: "Continue learning on the Brain Health PRO site.",
        accountHelp: "Account Help",
        accountHelpText: "We can help you log in or troubleshoot questions.",
        about: "Brain Health PRO was created by experts in brain health and aging. The program gives you essentials tips on how to keep your brain and cognition healthy as you age.",
        aboutUs: "About us",
        aboutLink: "Link to sign up to be notified of the public release?",
        contact: "Contact us",
        admin: "Admin",
        lang: "Français",
        // Profile(Metrics)
        brainHealthProfileTitle: "Your Brain Health Profile",
        overallBrainHealth: "Overall Brain Health",
        profileDesc1: "Based on your answers to the lifestyle questionnaires, here are your current risk profiles.",
        profileDesc2: "To see how your risk in a topic has changed, click on it.",
        // Topics
        chapters: "chapters",
        topicsDesc: "This is your progress in each of the eight Brain Health PRO topics. Click on one of them to see the list of chapters",
        of: "of",
        priorityTopic: "Priority Topic",
        // Notebook
        notebookInstructions:
            "Please select the topic you want to download with the down arrow (⯆). Alternatively, you can simply click on 'Every Topic' to download the complete Notebook",
        notebookInfo1: "Here you will find your notebook. You can use in three different ways:",
        notebookInfo2: "You can download it as a .PDF file and either:",
        notebookInfo3: "Fill it out electronically using Adobe Reader.",
        notebookInfo4: "Print it or ask someone to print it to write your notes.",
        notebookInfo5:
            "For either of these options, click on the down arrow to select the topic notebook you want to download. Alternatively, you can simply click on “Every Topics” to download the complete Notebook including the eight topics.",
        notebookInfo6: "Create your own using the Brain Health PRO model.",
        notebookInfo7:
            "Complete it on screen. To have access to it while viewing a chapter, you will need to open two tabs on your internet browser, one with the Notebook, and one with the chapter you want to view.",
        everyTopic: "Every topic",
        // Help
        helpType: "Help Type",
        submit: "Send support email",
        faq: "Frequently Asked Questions",
        replayIntro: "Replay the orientation chapter",
        replayFirstStep: "Replay the First step chapter",
        replayInteractiveActivities: "Replay the interactive activities chapter",
        question1: "1.	When can I change my priorities?",
        answer1: "You can’t change your priorities once you selected them",
        question2: "2.	How can I watch completed chapters again? ",
        answer2: "Click “Completed” on your dashboard",
        question3: "3.	Something came up and I couldn’t complete my chapters last week. What can I do?",
        answer3: "Don’t worry, the non-completed chapters remain available on your home page",
        help1: "If you need technical help or have questions about Brain Health PRO, you are at the right place.",
        help2: "In this section, you can:",
        help3: "Review the Orientation chapter that contains a lot of information.",
        help4: "Read the answers to Frequently Asked Questions.",
        help5: "Still stuck, you can write to us.",
        download: "download",
        // Login
        loginTitle: "Login",
        enterEmail: "Enter your email",
        password: "Password",
        forgotPassword: "Forgot Password",
        // Topic
        topic: "Topic",
        topicDesc: "To view an unlocked chapter, just click on it",
        fullHeartLegend: "Liked chapter",
        outlineHeartLegend: "Not liked chapter",
        greenCheckmarkLegend: "Completed chapter",
        outlineCheckmarkLegend: "Not completed chapter",
        // Register
        registerTitle: "Register for Brain Health",
        step1: "Step 1 : Enter Email Address",
        next: "Next",
        youWillNeed: "You will need",
        yourEmail: "Your email address",
        moreThanOne: "If you have more than one email address, use the address that you gave us.",
        "5min": "About 5 minutes",
        "5minDesc": "Setting up an account takes about 5 minutes.",
        step2: "Step 2 : Confirm your email address",
        correctEmail: "Is this your email address?",
        confirm: "Confirm",
        back: "Back",
        step3: "Step 3 : Enter Password",
        enterPassword: "Enter your password",
        confirmPassword: "Confirm your password",
        step4: "Step 4 : Enter additional information",
        nickname: "Nickname",
        dateOfBirth: "Date of Birth",
        language: "Language",
        // Goals
        goal: "Goal",
        goals: "Your Goals",
        goalsComplete: "Did you complete the goals you set last time?",
        goalsTitle: "Goal Selection",
        pleaseEnter: "Please enter a",
        new: "new",
        goalsTopics: "goal for each of these topics",
        prioritizedTopic: "Prioritized Topics",
        prioritizedTopics: "Time to select your three prioritized topics",
        selectTopics: "Please select 3 topics",
        riskTopics: "Topics at risk are preselected",
        selectGoal1: "Click to select first goal",
        selectGoal2: "Click to select second goal",
        selectGoal3: "Click to select third goal",
        // Topic Picker
        desiredTopic1: "Please choose your first topic",
        desiredTopic2: "Please choose your second topic",
        desiredTopic3: "Please choose your third topic",
        topicPicker: "Topic selection",
        topicInfo: "Move your mouse or finger over the name of a topic to see the associated risk profile",
        // Goal Picker
        desiredGoal1: "Please choose your first goal",
        desiredGoal2: "Please choose your second goal",
        desiredGoal3: "Please choose your third goal",
        goalPicker: "Goal selection",
        goalTitleInfo: "It is time to enter a goal for each of your prioritized topics. Click on each box and write down your goal.",
        goalListInfo:
            "Remember, set goals that are important, doable and enjoyable. Here are some questions that can help you create specific goals:",
        goalInfo1: "What will you do?",
        goalInfo2: "How much will you do it?",
        goalInfo3: "How often will you do it?",
        goalInfo4: "Where and when will you do it?",
        goalInfo5: "Who will you do it with?",
        goalInfo6: "How will you track your progress?",
        // Snackbars
        missingPermissions: "Missing permissions",
        emailSent: "Email Sent Successfully",
        emailInvalid: "Invalid email",
        userNotFound: "No user with this email",
        wrongPassword: "Password is incorrect",
        pscidMustBeDefined: "PSCID must be defined",
        whitelistUserCreated: "Whitelist user created",
        whitelistUserUpdated: "Whitelist user updated",
        userUpdated: "User updated",
        userCreated: "User created",
        noRequiredRole: "You don't have the required role",
        chapterModified: "Chapter modified",
        chapterCreated: "Chapter created",
        "1pointCompletedGained": "You gained",
        "1pointCompletedChapter": "for completing a chapter",
        "1pointCompletedGoals": "for completing your goals",
        "1pointCompletedPrioritized": "for selecting your prioritized topics",
        "1pointCompletedQuestionnaire": "for completing a questionnaire",
        goalsSelected: "Selected your goals",
        selectTopic1: "Click to select first prioritized topic",
        selectTopic2: "Click to select second prioritized topic",
        selectTopic3: "Click to select third prioritized topic",
        stageCompleted: "Questionnaires {{stage}} completed",
        participantCreated: "Participant created successfully",
        participantError: "An error occured white creating the participant",
        participantAlreadyExist: "The participant already exists",
        participantNotWhitelisted: "The participant is not whitelisted",
        questionnaireCompleted: "Completed {{title}} form",
        participantNotFound: "Participant not found",
        participantDeleted: "Participant is deleted",
        participantDisabled: "Participant is disabled",
        networkError: "Network error",
        // Questionnaire instructions
        questionnaireInstructions: "Questionnaire instructions",
        yes: "Yes",
        no: "No",
        questionnaireComplete:
            "It is important to click the “Home” button located inside the chapter, on the final slide, to collect your reward and have this chapter appear as completed on your Home page",
        // Completed
        completedDesc: "Here are your completed chapters. You can view them again by clicking on them",
        // Favourites
        favDesc: "Here are your favourite chapters. If you want to view one of them again, just click on it",
        // Disclaimer
        disclaimer: "Disclaimer",
        disclaimerText: "You are about to complete a series of questionnaires.",
        disclaimerText2: "This task should take about 20 minutes.",
        disclaimerText3: "Please complete them all in one session and do not close the program while doing so.",
        disclaimerText4:
            "If you close the program before completing all the questionnaires, you will be asked to complete them all from the beginning when you reopen the program.",
        agree: "Agree",
        // Forgot Password
        forgotPasswordDesc:
            "If you have forgotten your password, please enter your email address. If this email is valid, you will receive a message with instructions on how to reset your password.",
        send: "Submit",
        // Metric
        stage1Title: "Start",
        stage2Title: "12 weeks",
        stage3Title: "23 weeks",
        stage4Title: "34 weeks",
        stage5Title: "45 weeks",
        current: "Current",
        // Overview
        continue: "Continue",
        // Routes
        landing: "Landing",
        routeLogin: "Login",
        completeChapter: "Complete Chapter",
        yourProfile: "Your Profile",
        favouriteTopic: "Favourite Topics",
        completedTopics: "Completed Topics",
        overview: "Overview",
        finalOverview: "Final Overview",
        myNotebook: "My Notebook",
        formTest: "Form Test",
        questionnaires: "Questionnaires",
        // Final Overview
        finalOverviewInformation: "Final overview information",
        export: "Export",
        // Library
        library: "Library",
        libraryInfo: "Click on the topic to display the chapters",
    },
};
