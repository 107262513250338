import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

// Interfaces
import { StatTopic } from "../../interfaces/Stat";

// Utils
import { getGaugeInfoAndDangerLevel, getIconFromTopic, getTitleFromTopic } from "../../utils/topic";

interface GaugeProps {
    gaugeValue: number;
    animation?: boolean;
    scale?: number;
}

export const Gauge: React.FC<GaugeProps> = ({ gaugeValue, animation, scale }) => {
    const { t } = useTranslation();

    const rotate = keyframes`
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(${gaugeValue}deg);
        }
    `;

    const Rotate = styled.div`
        background-color: red;
        animation: ${rotate} 2s 1 both;
        animation-delay: 1s;
    `;

    return (
        <>
            <div className="gauge__container">
                <div className="gauge-wrapper">
                    <div className="gauge four rischio3" style={{ transform: `scale(${scale && scale * 1})` }}>
                        <div className="slice-colors">
                            <div className="st slice-item"></div>
                            <div className="st slice-item"></div>
                            <div className="st slice-item"></div>
                            <div className="st slice-item"></div>
                        </div>
                        {animation ? (
                            <Rotate>
                                <div className="needle"></div>
                            </Rotate>
                        ) : (
                            <div style={{ transform: `rotate(${gaugeValue}deg)` }}>
                                <div className="needle"></div>
                            </div>
                        )}
                        <div className="gauge-center">
                            {/* <div className="label">RISK</div> */}
                            <div className="number">{t("risk")}</div>
                        </div>
                    </div>
                    <div className="gauge-label cancelnofilterimg">
                        <p>{t("low")}</p>
                        <p>{t("high")}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

interface GaugeCardProps {
    topic: StatTopic;
    score: number;
    title?: string;
    isClickable?: boolean;
    hasAnimation?: boolean;
}

const GaugeCard: React.FC<GaugeCardProps> = ({ topic, score, title, isClickable = true, hasAnimation = true }) => {
    const { t } = useTranslation();
    const hist = useHistory();

    const [gaugeValueLocal, setGaugeValueLocal] = useState(0);
    const [riskClassLocal, setRiskClassLocal] = useState("");
    const [riskLabelLocal, setRiskLabelLocal] = useState("");

    useEffect(() => {
        const { gaugeValue, riskClass, riskLabel } = getGaugeInfoAndDangerLevel(topic, score);

        // console.log("------------------------------");
        // console.log("topic", topic);
        // console.log("score", score);
        // console.log("riskClass", riskClass);
        // console.log("gaugeValue", gaugeValue);
        // console.log("------------------------------");

        let translatedRiskLabel = t("lowRisk");

        if (riskLabel === "Low Risk") {
            translatedRiskLabel = t("lowRisk");
        }
        if (riskLabel === "Moderate Risk") {
            translatedRiskLabel = t("moderateRisk");
        }
        if (riskLabel === "High Risk") {
            translatedRiskLabel = t("highRisk");
        }

        setGaugeValueLocal(gaugeValue);
        setRiskClassLocal(riskClass);
        setRiskLabelLocal(translatedRiskLabel);
    }, []);

    if (title) {
        return (
            <>
                <div className={`gaugeCard__container gaugeCard__${riskClassLocal}`}>
                    <div style={{ textAlign: "center", backgroundColor: "transparent" }}>
                        {!title && <img src={getIconFromTopic(topic)} alt="" />}
                        <h3>{!title ? getTitleFromTopic(topic) : title}</h3>
                        <h5 style={{ marginBottom: 20 }}>
                            <b>{riskLabelLocal}</b>
                        </h5>
                    </div>
                    <Gauge gaugeValue={gaugeValueLocal} animation={hasAnimation} />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div
                    className={`nofilterimg gaugeCard__container gaugeCard__${riskClassLocal}`}
                    style={{ cursor: isClickable ? "pointer" : "" }}
                    onClick={() => (isClickable ? hist.push(`/profile/${topic}`) : {})}
                >
                    <div className="gaugeCard__description">
                        <img src={getIconFromTopic(topic)} alt="" />
                        <div className="gaugeCard__title cancelnofilterimg">
                            <h3>{getTitleFromTopic(topic)}</h3>
                            <h5>
                                <b>{riskLabelLocal}</b>
                            </h5>
                        </div>
                    </div>
                    <Gauge gaugeValue={gaugeValueLocal} animation={hasAnimation} />
                </div>
            </>
        );
    }
};

export default GaugeCard;
