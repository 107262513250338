import React, { useEffect, useState } from "react";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Mui
import Paper from "@mui/material/Paper";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Settings
import settings from "../../../settings.json";

// Interfaces
import { Participant } from "../../../interfaces/Participant";

// Components
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import Loading from "../../general/Loading";
import SubDomainSignin from "../../pages/portal/SubDomainSignIn";

const MainLayout: React.FC = ({ children }) => {
    const { currentParticipant, currentWeek, loading } = useFetchUser();

    // States
    const [width, setWidth] = useState(window.innerWidth);

    const handleResize = () => setWidth(window.innerWidth);

    // Window closing listener
    window.addEventListener("beforeunload", async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index !== -1) {
                    if (participant.uses[index]) {
                        participant.uses[index].end = new Date();

                        await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses],
                        });
                    }
                }
            }
        }
    });

    window.addEventListener("resize", handleResize);

    const updateUses = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index === -1) {
                    await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                        uses: [...participant.uses, { start: new Date() }],
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (currentWeek > 0) updateUses();
    }, [currentWeek]);

    return (
        <>
            <SubDomainSignin />
            <Loading loading={loading} />
            <div className="mainLayout__container">
                {width >= settings.page.maxScreenWidth ? <DesktopDrawer /> : <MobileDrawer />}
                <main className="mainLayout__content">
                    <div className="mainLayout__appBarSpacer" />
                    <div className="mainLayout__info">
                        <Paper style={{ padding: 24 }}>{children}</Paper>
                    </div>
                </main>
            </div>
        </>
    );
};

export default MainLayout;
